.custom_google_login {
  border: 1px solid #dbdbdb !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  font-family: 'Open Sans', sans-serif !important;
}

.custom_google_login,
.custom_google_login span {
  width: 100% !important;
  height: 41px !important;
}
.custom_google_login span {
  font-family: 'Open Sans', sans-serif !important;
  /* color: #000 !important; */
  color: #3c3c3c !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 22px;
}
