.PhoneInput {
    border: 1px solid #dbdbdb;
    border-radius: 0.375rem;
    padding: 7px 10px;
    display: flex;
    align-items: center;
}
.PhoneInput--focus .PhoneInputInput {
    outline: 0px !important;
}

.phone-input-disabled .PhoneInput,
.phone-input-disabled .PhoneInput .PhoneInputInput,
.phone-input-disabled .PhoneInput .PhoneInputCountry {
    cursor: not-allowed !important;
}

.phone-input-disabled .PhoneInput {
    background: #cccccc40 !important;
}